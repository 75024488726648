import React, { useEffect, useState } from "react";
import {
    Container,
    FormControl,
    FormControlLabel,
    Grid, InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select, TextField,
    Typography
} from "@mui/material";
import { ReactComponent as Logo } from './images/Moo_Do_tkd_logo.svg'
import FinalistsGrid from "./Components/FinalistsGrid";
import {isBefore, parseISO} from "date-fns";

function App() {
    const [seasonData, setSeasonData] = useState([])
    const [season, setSeason] = useState('')
    const [tournamentData, setTournamentData] = useState([])
    const [tournament, setTournament] = useState('')
    const [divisionData, setDivisionData] = useState([])
    const [seasonDivisionData, setSeasonDivisionData] = useState([])
    // 1 is Tournament, 2 is Season
    const [viewType, setViewType] = useState(2)
    const [divEventData, setDivEventData] = useState([])
    const [selectedDivEvent, setSelectedDivEvent] = useState('')
    const [search, setSearch] = useState('')

    
    useEffect(() => {
        const getSeasonData = async () => {
            try {
                const reqUrl = `${process.env.REACT_APP_API_ROOTURL}/seasons`
                const response = await fetch(reqUrl);
                const myResponse = await response.json();
                setSeasonData(myResponse);
                // let maxSeason = Math.max([...myResponse.filter(f => {
                //     return isBefore(parseISO(f.StartDate), new Date())
                // })].map(a => a.id))
                let maxSeason = Math.max(...myResponse.map(a => a.id))
                setSeason(maxSeason)
            } catch (e) {
                console.log(e.message);
            }
        }
        getSeasonData()
    },[])
    useEffect(() => {
        const getTournamentData = async () => {
            try {
                const reqUrl = `${process.env.REACT_APP_API_ROOTURL}/tournaments?season=${season}`
                const response = await fetch(reqUrl);
                const myResponse = await response.json();
                let newMax = [...myResponse.filter(f => {
                    return isBefore(parseISO(f.eventDate), new Date())
                })].sort(function(a,b) {
                    return Date.parse(b.eventDate) - Date.parse(a.eventDate)
                })
                setTournamentData(newMax);
                setTournament(newMax[0].id)
            } catch (e) {
                console.log(e.message);
            }
        }
        getTournamentData()
    },[season])
    useEffect(() => {
        const getDivisionData = async () => {
            try {
                let reqUrl = `${process.env.REACT_APP_API_ROOTURL}/divisions?withCompetitors=true&search=${search}&`
                if (parseInt(viewType) === 1) { // Tournament
                    if (isNaN(parseInt(tournament)))
                        throw new Error("Invalid tournament value. Continuing...")
                    reqUrl = `${reqUrl}tournamentId=${tournament}&divEventId=${selectedDivEvent}`
                } else { // Season
                    if (isNaN(parseInt(season)))
                        throw new Error("Invalid season value. Continuing...")
                    reqUrl = `${process.env.REACT_APP_API_ROOTURL}/divisions?withCompetitors=true&seasonId=${season}&search=${search}&`
                    // throw new Error("No need to re-request. Continuing...")
                //     reqUrl = `${reqUrl}seasonId=${season}&divEventId=${selectedDivEvent}`
                }

                const response = await fetch(reqUrl);
                const myResponse = await response.json();
                console.log(reqUrl, myResponse)
                setDivisionData(myResponse);
            } catch (e) {
                console.log(e.message);
            }
        }
        getDivisionData()
    },[season, tournament, selectedDivEvent, viewType, seasonData, tournamentData, search])
    useEffect(() => {
        const getSeasonDivisionData = async () => {
            try {
                // Not using Search in Finalists because we want to show the whole division.
                let reqUrl = `${process.env.REACT_APP_API_ROOTURL}/finalists?seasonId=${season}&divEventId=${selectedDivEvent}`
                if (parseInt(viewType) === 2) { // Season
                    if (isNaN(parseInt(season)))
                        throw new Error("Invalid season value. Continuing...")
                }

                const response = await fetch(reqUrl);
                const myResponse = await response.json();
                setSeasonDivisionData(myResponse);
            } catch (e) {
                console.log(e.message);
            }
        }
        getSeasonDivisionData()
    },[season, selectedDivEvent, viewType])
    useEffect(() =>{
        const getDivEventData = async () => {
            try {
                const reqUrl = `${process.env.REACT_APP_API_ROOTURL}/divEvents?points=true`
                const response = await fetch(reqUrl);
                const divEventResponse = await response.json();
                divEventResponse.unshift({id: '', description: '<Any Category>'})
                setDivEventData(divEventResponse);
            } catch (e) {
                console.log(e.message);
            }
        }
        getDivEventData()
    }, [])

    const HandleChange = (event) => {
        const { name, value } = event.target
        switch(name) {
            case "viewType":
                setViewType(parseInt(value));
                break;
            case "season":
                setSeason(value);
                break;
            case "search":
                setSearch(value);
                break;
            case "tournament":
                setTournament(value);
                break;
            case "selectedDivEvent":
                setSelectedDivEvent(value);
                break;
            default:
                console.error("Unrecognized field: ", name, value)
        }
    }
    return (
    <div>
        <Container className="pointsContainer">
            <Grid container direction="row">
                <Grid item xs={2}>
                    <Logo width={60} />
                </Grid>
                <Grid item xs={10}>
                    <Typography color="textPrimary" gutterBottom variant="h3" align="center">
                        Moo Do Taekwondo Association Points
                    </Typography>
                </Grid>
            </Grid>
            <Grid container wrap="wrap">
                <Grid item xs={6} className="wideText">
                    <Typography color="textPrimary" gutterBottom variant="p" align="left">
                        Points within a division are now viewed in two ways:
                    <RadioGroup
                        name="viewType"
                        value={viewType}
                        onChange={HandleChange}
                    >
                        <FormControlLabel value={1} control={<Radio />} label="How did I do in my division at a tournament?" />
                        <FormControlLabel value={2} control={<Radio />} label="What is my division standing for the season?" />
                    </RadioGroup>
                    </Typography>
                </Grid>
                <Grid item xs={6} hidden={parseInt(viewType)===2} className="wideText">
                    <ul>
                        <li>Competitors are placed in divisions based on their age on the date of the tournament.</li>
                        <li>
                            Division ages at a tournament may not match tournament season division ages, but the points 
                            will follow the competitor or team for finalist point calculation. 
                        </li>
                        <li>When you see two (or more) divisions with the same or overlapping descriptions, 
                            that means that the division was split.</li>
                        <li>Division composition will change from tournament to tournament to fascilitate competition.</li>
                    </ul>
                </Grid>
                <Grid item xs={6} hidden={parseInt(viewType)===1} className="wideText">
                    <ul>
                        <li>Competitors are placed in divisions based on their age on the last day of the tournament season.</li>
                        <li>
                            If you see a team in two different divisions in the same category, that means that one or 
                            both of the competitors have aged up. There is a 1 year age grace period so a team will be 
                            placed appropriately in the finals.
                        </li>
                    </ul>
                </Grid>
            </Grid>
            <Grid container wrap="wrap">
                <Grid item xs={6} className="wideDropdown">
                    <FormControl fullWidth>
                        <InputLabel id="season-label">Season</InputLabel>
                        <Select
                            id="season"
                            name="season"
                            value={season}
                            label="Season"
                            onChange={HandleChange}
                        >
                            {seasonData && seasonData.map((seasonItem, index) => {
                                return (<MenuItem key={index} value={seasonItem.id}>{seasonItem.description}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} hidden={parseInt(viewType)===2} className="wideDropdown">
                    <FormControl fullWidth>
                        <InputLabel id="season-label">Tournament</InputLabel>
                        <Select
                            id="tournament"
                            name="tournament"
                            value={tournament}
                            label="tournament"
                            onChange={HandleChange}
                        >
                            {tournamentData && tournamentData.map((tournamentItem, index) => {
                                return (<MenuItem key={index} value={tournamentItem.id}>{tournamentItem.description}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container wrap="wrap" className="wideDropdown">
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="div-event-label">Category</InputLabel>
                        <Select
                            id="selectedDivEvent"
                            name="selectedDivEvent"
                            value={selectedDivEvent}
                            label="selectedDivEvent"
                            onChange={HandleChange}
                        >
                            {divEventData && divEventData.map((divEventItem, index) => {
                                return (<MenuItem key={index} value={divEventItem.id}>{divEventItem.description}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField id="search" name="search" label="Search" variant="outlined" value={search} onChange={HandleChange} />
                </Grid>
            </Grid>
            <Grid container wrap="wrap" >
                {divisionData && divisionData.map((divisionItem, index) => {
                    return (
                        <Grid item xs={3} key={index} className="divisionCard" hidden={parseInt(viewType)===2}>
                            <span className="divisionHead">{divisionItem.description}</span>
                            <ul>
                            {divisionItem.competitors && divisionItem.competitors.map((competitor, index) => {
                                return (
                                    <li key={index}>
                                        {(competitor.team !== null)?competitor.points + ' ' + competitor.team.name:''}
                                        {(competitor.person !== null)?competitor.points + ' ' + competitor.person.first + ' ' + competitor.person.last:''}
                                    </li>
                                )
                            })}
                            </ul>
                        </Grid>
                    )
                })}
            </Grid>
            <FinalistsGrid finalistsData={seasonDivisionData} search={search} hidden={parseInt(viewType)===1} />
        </Container>
    </div>
    );
}

export default App;