import {Grid} from "@mui/material";
import React from "react";


const FinalistsGrid = ({finalistsData, search, hidden}) => {
    //const [shapedData, setShapedData] = useState([])
    
    // This is a filtered list to get the divisions that contain the search term
    const filteredDivisions = 
        search === ''?
            finalistsData
            : finalistsData?.filter((finalist) => {
                return (
                    (
                        finalist.players && 
                        (finalist.players.filter((player) => {
                            return (player.first.toLowerCase() + ' ' + player.last.toLowerCase())
                                .trim()
                                .includes(search.toLowerCase())
                    }).length > 0)) ||
                    ((finalist.teams && 
                        finalist.teams.filter((team) => {
                        return team.name.toLowerCase()
                            .trim()
                            .includes(search.toLowerCase())
                    }).length>0)
                    )
                )
            })

    // This list includes everyone from the filtered divisions
    const filteredFinalists = 
        search === ''?
            finalistsData
            :finalistsData?.filter((division) => {
                return (
                    filteredDivisions.filter((divs) => {
                        return divs.id === division.id
                    }).length > 0)
            })

    
    return (
        <Grid container wrap="wrap">
            {filteredFinalists && filteredFinalists.map((divisionItem, index) => {
                return (
                    <Grid item xs={3} key={index} className="divisionCard" hidden={hidden}>
                        <span className="divisionHead">{divisionItem.description}</span>
                        <ul>
                            {divisionItem.players && divisionItem.players.sort((a,b)=> {return (a.points < b.points)?1:-1}).map((player, index) => {
                                return (
                                    <li key={index}>{player.points} - {player.first} {player.last}</li>
                                )
                            })}
                            {divisionItem.teams && divisionItem.teams.sort((a,b)=> {return (a.points < b.points)?1:-1}).map((team, index) => {
                                return (
                                    <li key={index}>{team.points} - {team.name}</li>
                                )
                            })}
                        </ul>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default FinalistsGrid